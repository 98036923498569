export type AgreementModel = {
  cache_key: number,
  data: object,
  dates: Record<string, any>,
  document_template_id: number,
  id: number,
  options: object,
  owner_id: number,
  owner_type: string,
  project: number,
  project_id: number,
  quote_ids: number[] | null,
  recipients: any[],
  status_id: number,
  subject_id: number,
  subject_type: string,
  title: string,
  token: string,
  type: string,
  urls: Record<string, any>,
}

const state = {
  data: {},
  document_template_id: '',
  id: null,
  options: {},
  owner_id: '',
  owner_type: '',
  status_id: '',
  subject_id: '',
  subject_type: '',
  title: '',
  token: '',
}

export const requiredFields = [
  { key: 'owner_id' },
  { key: 'owner_type' },
  { key: 'subject_id' },
  { key: 'subject_type' },
  { key: 'title' },
]

export default state
