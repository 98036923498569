import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import isMobileBrowser from 'is-mobile'

import {
  digObject,
  getQueryParamsFromHash,
  parsePermittedQueryParams,
  sortArrayBy,
} from '@campaignhub/javascript-utils'

import {
  useForm, useLatestEntity, useThunkDispatch, useWatchEntityUpdates,
} from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import generateRedirectUrl from '@functions/generateRedirectUrl'
import { buildExternalAgreementUrl } from '@functions/agreement'

import useMixpanel from '@hooks/useMixpanel'
import useOrganizationSelector from '@hooks/useOrganizationSelector'

import defaultFormState, { requiredFields } from '@models/agreement'

import * as agreementActions from '@redux/modules/agreement'

const watchEntityKeys = ['agreements', 'documentRecipients']

const generateUrls = (agreement) => {
  const { id } = agreement

  return {
    editAgreementUrl: `#/agreements/${id}/edit?redirect=${generateRedirectUrl()}`,
    editExternalAgreementUrl: buildExternalAgreementUrl({ agreement }),
  }
}

const createAgreement = (params) => {
  const {
    agreement, dispatch, dispatchMixpanelEvent, requestOptions,
  } = params
  const { createAgreement: createFn } = agreementActions

  return dispatch(createFn(agreement, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const {
        entity: { id },
      } = data

      const { editAgreementUrl } = generateUrls({ id })

      dispatchMixpanelEvent('Agreement Created', {
        integration_platform: 'Engage',
      })

      return {
        success,
        data,
        redirectUrl: editAgreementUrl,
      }
    }

    return response
  })
}

const updateAgreement = (agreement, agreementParams, dispatch, requestOptions) => {
  const { updateAgreement: updateFn } = agreementActions
  const { data, options } = agreementParams

  const updatedParams = {
    ...agreementParams,
    id: agreement.id,
  }

  // Prevent overwriting page options accidentally
  // in case all options are not passed back in params object
  if (options){
    const agreementOptions = agreement.options || {}
    updatedParams.options = JSON.stringify({
      ...cloneDeep(agreementOptions),
      ...options,
    })
  }

  if (data){
    const agreementData = agreement.data || {}
    updatedParams.data = JSON.stringify({
      ...cloneDeep(agreementData),
      ...data,
    })
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

const updateRecipientSortOrder = (agreement, sortedRecipientIds, dispatch, requestOptions) => {
  const { updateRecipientSortOrder: updateSortFn } = agreementActions
  return dispatch(updateSortFn(agreement, sortedRecipientIds, requestOptions))
}

const deleteAgreement = (agreement, dispatch, requestOptions) => {
  const { deleteAgreement: deleteFn } = agreementActions

  return dispatch(deleteFn(agreement, requestOptions)).then((response) => {
    const { success, data } = response
    if (success){
      const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

      return {
        success,
        data,
        redirectUrl: redirect,
      }
    }

    return response
  })
}

const loadAgreementShortcodeData = (agreement, dispatch, requestOptions) => {
  const { loadAgreementShortcodeData: loadFn } = agreementActions
  return dispatch(loadFn(agreement, requestOptions))
}

const selectDocumentTemplateId = (params) => {
  const {
    agreement, dispatch, dispatchMixpanelEvent, documentTemplateId, requestOptions,
  } = params

  const updatedAgreement = {
    ...agreement,
    document_template_id: documentTemplateId,
  }

  if (agreement.id){
    return updateAgreement(agreement, updatedAgreement, dispatch, requestOptions)
  }

  return createAgreement({
    agreement: updatedAgreement, dispatch, dispatchMixpanelEvent, requestOptions,
  })
}

const deleteExternalAgreement = (params) => {
  const { agreement, dispatch, electronicSigningPlatformKey } = params

  const { deleteExternalAgreement: deleteFn } = agreementActions

  const options = {
    integration_platform_key: electronicSigningPlatformKey,
    organization_id: agreement.owner_id,
  }

  return dispatch(deleteFn(agreement, options))
}

const sendExternalAgreement = (params) => {
  const { sendExternalAgreement: sendFn } = agreementActions

  const {
    agreement, dispatch, electronicSigningPlatformKey, modalKey,
  } = params

  const options = {
    integration_platform_key: electronicSigningPlatformKey,
    organization_id: agreement.owner_id,
  }

  return dispatch(sendFn(agreement, options)).then((response) => {
    const { success, data } = response
    if (success){
      const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

      return {
        success,
        data,
        redirectUrl: modalKey ? `${redirect}?agreementId=${agreement.id}&showModal=${modalKey}` : redirect,
      }
    }

    return response
  })
}

const generateExternalAgreementSigningUrl = (params) => {
  const {
    agreement, dispatch, electronicSigningPlatformKey, modalKey, recipientId, selectedOrganization,
  } = params

  const { generateExternalAgreementSigningUrl: generateUrlFn } = agreementActions

  const baseRedirectUrl = `${window.location.href.split('?')[0]}?agreementId=${
    agreement.id
  }&documentRecipientId=${recipientId}`

  const options = {
    document_recipient_id: recipientId,
    integration_platform_key: electronicSigningPlatformKey,
    organization_id: selectedOrganization.id,
    redirect: modalKey ? `${baseRedirectUrl}&showModal=${modalKey}` : baseRedirectUrl,
  }

  return dispatch(generateUrlFn(agreement, options))
}

const voidExternalAgreement = (params) => {
  const {
    agreement, dispatch, electronicSigningPlatformKey, voidReason,
  } = params

  const { voidExternalAgreement: voidFn } = agreementActions

  const options = {
    integration_platform_key: electronicSigningPlatformKey,
    organization_id: agreement.owner_id,
    void_reason: voidReason,
  }

  return dispatch(voidFn(agreement, options))
}

const generateExternalFormUrl = (params) => {
  const {
    agreement, createFormPlatformKey, dispatch, requestOptions,
  } = params

  const { generateExternalFormUrl: generateUrlFn } = agreementActions

  const options = {
    integration_platform_key: createFormPlatformKey,
    organization_id: agreement.owner_id,
    ...requestOptions,
  }

  return dispatch(generateUrlFn(agreement, options))
}

const generateNativeAppUrl = (params) => {
  const {
    agreement, createFormPlatformKey, dispatch, requestOptions,
  } = params

  const { generateNativeAppUrl: generateNativeAppUrlFn } = agreementActions

  const options = {
    integration_platform_key: createFormPlatformKey,
    ...requestOptions,
  }

  return dispatch(generateNativeAppUrlFn(agreement, options))
}

const selectDocumentTemplate = params => new Promise((resolve, reject) => {
  const {
    agreement, dispatch, dispatchMixpanelEvent, showSelectDocumentTemplateModal,
  } = params

  if (showSelectDocumentTemplateModal){
    const payload = {
      callbacks: {
        selectDocumentTemplateId: (documentTemplateId, requestOptions) => selectDocumentTemplateId({
          agreement,
          dispatch,
          dispatchMixpanelEvent,
          documentTemplateId,
          requestOptions,
        }),
      },
    }

    showSelectDocumentTemplateModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showSelectDocumentTemplateModal not defined in PageContext callbacks'))
})

const viewAgreementEvents = (agreement, showAgreementExternalEventsModal) => new Promise((resolve, reject) => {
  if (showAgreementExternalEventsModal){
    const payload = {
      agreement,
    }

    showAgreementExternalEventsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showAgreementExternalEventsModal not defined in PageContext callbacks'))
})

const viewAgreementProgress = (agreement, showAgreementProgressModal) => new Promise((resolve, reject) => {
  if (showAgreementProgressModal){
    const payload = {
      agreement,
    }

    showAgreementProgressModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showAgreementProgressModal not defined in PageContext callbacks'))
})

export function useAgreementForm(agreement = {}, options = {}){
  const { customRequiredFields = [] } = options

  const { creating, updating } = useSelector(reduxState => reduxState.agreements)

  const agreementForm = useForm(
    defaultFormState,
    { entity: agreement, requiredFields: [...requiredFields, ...customRequiredFields], validateOn: 'change' },
    [agreement.id, agreement.cache_key],
  )

  return {
    ...agreementForm,
    creating,
    updating,
  }
}

export function useRelations(agreement = {}){
  const {
    document_template_id, owner_id, owner_type, status_id, subject_id, subject_type,
  } = agreement

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    documentTemplates, organizations, projects, statuses,
  } = entities

  const documentTemplate = documentTemplates[document_template_id] || {}
  const organization = owner_type === 'Organization' ? organizations[owner_id] || {} : {}
  const project = subject_type === 'Project' ? projects[subject_id] || {} : {}
  const status = statuses[status_id] || {}

  return {
    documentTemplate,
    organization,
    project,
    status,
  }
}

function useAgreement(initEntity = {}){
  const { entity: agreement } = useLatestEntity(initEntity, 'agreements')
  const { id } = agreement

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { showAgreementExternalEventsModal, showAgreementProgressModal, showSelectDocumentTemplateModal } = callbacks || {}

  const {
    updatedEntities: { agreements: agreementsUpdatedAt, documentRecipients: documentRecipientsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useThunkDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const { documentRecipients, integrationPlatforms, statuses } = entities

  const { creating, updating } = useSelector(reduxState => reduxState.agreements)

  const agreementRelations = useRelations(agreement)
  const { status } = agreementRelations

  const { selectedOrganization } = useOrganizationSelector()

  // Recipients
  const filteredRecipients = useMemo(() => {
    const array = Object.values(documentRecipients)
    const filtered = array.filter((documentRecipient) => {
      const { owner_id, owner_type } = documentRecipient

      return owner_id === id && owner_type === 'Agreement'
    })
    const sorted = sortArrayBy(filtered, 'asc', 'sort')

    return sorted
  }, [id, agreementsUpdatedAt, documentRecipientsUpdatedAt])

  const groupedRecipients = useMemo(() => {
    const grouped = filteredRecipients.reduce(
      (acc, recipient) => {
        const { in_person_signer } = recipient

        if (in_person_signer){
          acc.inPersonSigners.push(recipient)
        }

        if (!in_person_signer){
          acc.remoteSigners.push(recipient)
        }

        return acc
      },
      { inPersonSigners: [], remoteSigners: [] },
    )

    return grouped
  }, [id, agreementsUpdatedAt, documentRecipientsUpdatedAt])

  const permittedStatusKeys = ['pending', 'sent', 'accepted', 'completed', 'voided']
  const filteredStatuses = Object.values(statuses).filter(s => permittedStatusKeys.includes(s.key))

  const isCompleted = status.key === 'completed'
  const isPending = status.key === 'pending'
  const isSent = status.key === 'sent'
  const isVoided = status.key === 'voided'

  const electronicSigningPlatformKey = digObject(agreement, 'data.electronic_signing_platform')
  const electronicSigningExternalId = digObject(agreement, `data.external_ids.${electronicSigningPlatformKey}`)

  const createFormPlatformKey = digObject(agreement, 'data.create_form_platform')
  const createFormExternalId = digObject(agreement, `data.external_ids.${createFormPlatformKey}`)
  const createFormIntegrationPlatform = Object.values(integrationPlatforms).find(
    integrationPlatform => integrationPlatform.key === createFormPlatformKey,
  )

  const isMobile = isMobileBrowser({ featureDetect: true })
  const isTablet = isMobileBrowser({ tablet: true, featureDetect: true })

  const isMobileDevice = isMobile || isTablet

  // Mixpanel Tracking
  const { callbacks: { dispatchMixpanelEvent } } = useMixpanel()

  return {
    agreement,
    callbacks: {
      createAgreement: (params, requestOptions) => createAgreement({
        agreement: params, dispatch, dispatchMixpanelEvent, requestOptions,
      }),
      deleteAgreement: requestOptions => deleteAgreement(agreement, dispatch, requestOptions),
      deleteExternalAgreement: () => deleteExternalAgreement({
        agreement,
        dispatch,
        electronicSigningPlatformKey,
      }),
      generateExternalAgreementSigningUrl: (recipientId, modalKey) => generateExternalAgreementSigningUrl({
        agreement,
        dispatch,
        electronicSigningPlatformKey,
        modalKey,
        recipientId,
        selectedOrganization,
      }),
      generateExternalFormUrl: () => generateExternalFormUrl({ agreement, createFormPlatformKey, dispatch }),
      generateNativeAppUrl: () => generateNativeAppUrl({ agreement, createFormPlatformKey, dispatch }),
      loadAgreementShortcodeData: requestOptions => loadAgreementShortcodeData(agreement, dispatch, requestOptions),
      selectDocumentTemplate: () => selectDocumentTemplate({
        agreement, dispatch, dispatchMixpanelEvent, showSelectDocumentTemplateModal,
      }),
      sendExternalAgreement: modalKey => sendExternalAgreement({
        agreement,
        dispatch,
        electronicSigningPlatformKey,
        modalKey,
      }),
      updateAgreement: (agreementParams, requestOptions) => updateAgreement(agreement, agreementParams, dispatch, requestOptions),
      updateRecipientSortOrder: (sortedRecipientIds, requestOptions) => updateRecipientSortOrder(agreement, sortedRecipientIds, dispatch, requestOptions),
      viewAgreementEvents: () => viewAgreementEvents(agreement, showAgreementExternalEventsModal),
      viewAgreementProgress: () => viewAgreementProgress(agreement, showAgreementProgressModal),
      voidExternalAgreement: voidReason => voidExternalAgreement({
        agreement,
        dispatch,
        electronicSigningPlatformKey,
        voidReason,
      }),
    },
    createFormExternalId,
    createFormIntegrationPlatform,
    createFormPlatformKey,
    creating,
    electronicSigningExternalId,
    electronicSigningPlatformKey,
    filteredRecipients,
    filteredStatuses,
    groupedRecipients,
    isCompleted,
    isMobileDevice,
    isPending,
    isSent,
    isSyncedWithCreateFormPlatform: !!createFormExternalId,
    isSyncedWithElectronicSigningPlatform: !!electronicSigningExternalId,
    isVoided,
    updating,
    urls: generateUrls(agreement),
  }
}

export default useAgreement
