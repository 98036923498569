import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCog, faDownload, faFileAlt, faPencil,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Link, ListItem, Text,
} from '@campaignhub/suit-theme'

import { snakeToTitleCase } from '@campaignhub/javascript-utils'

import useAgreement from '@hooks/useAgreement'
import useIntegrationPlatform from '@hooks/useIntegrationPlatform'
import useLocalization from '@hooks/useLocalization'

import localizedStrings from './localizedStrings'

const ExternalDocument = (props) => {
  const { agreement, projectState } = props

  const agreementPayload = useAgreement(agreement)
  const {
    agreement: { title, urls },
    createFormExternalId,
    createFormIntegrationPlatform,
    createFormPlatformKey,
    urls: { editExternalAgreementUrl },
  } = agreementPayload

  const { external_form_pdf: externalFormPdfUrl } = urls || {}

  const integrationPlatformPayload = useIntegrationPlatform(createFormIntegrationPlatform)
  const { supportedFeatureKeys } = integrationPlatformPayload

  const {
    callbacks: { formatString },
    strings,
  } = useLocalization(localizedStrings)

  const isInNsw = projectState === 'nsw'

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      disableHover
    >
      <Box flexDirection="column">
        <Text fontSize="small" marginBottom="medium">
          {title}
        </Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginBottom="medium">
          <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: 5 }} />
          {`${snakeToTitleCase(createFormPlatformKey)} | ${formatString(strings.formId, {
            id: createFormExternalId,
          })}`}
        </Text>
      </Box>

      <Box marginRight="medium" width="auto">
        {isInNsw && (
          <Button
            as="a"
            buttonStyle="secondaryEdit"
            href={editExternalAgreementUrl}
            icon={<FontAwesomeIcon icon={faPencil} />}
            marginRight="medium"
            size="medium"
            target="_blank"
            width="auto"
          >
            {strings.buttons?.edit || 'Edit'}
          </Button>
        )}

        {supportedFeatureKeys.includes('download_external_form') && (
          <IconWithMenu
            icon={(
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faCog} />}
                marginLeft="medium"
                size="medium"
                style={{ height: 37 }}
              />
            )}
            style={{ width: 'auto' }}
          >
            <IconWithMenu.Menu closeOnClick listStyle={{ right: '-7px' }}>
              <Link key="pdf" href={externalFormPdfUrl}>
                <FontAwesomeIcon icon={faDownload} /> {strings.iconWithMenu?.pdf || 'Download Agreement'}
              </Link>
            </IconWithMenu.Menu>
          </IconWithMenu>
        )}
      </Box>
    </ListItem>
  )
}

ExternalDocument.propTypes = {
  agreement: PropTypes.object.isRequired,
  projectState: PropTypes.string.isRequired,
}

export default ExternalDocument
