import { useSetState, useThunkDispatch } from '@campaignhub/react-hooks'

import * as currentUserActions from '@redux/modules/currentUser'
import * as userActions from '@redux/modules/user'

import type { AppDispatch } from '@redux/store'

import { defaultRequestOptions } from './useCurrentUser'

type ImpersonateUserParams = {
  dispatch: AppDispatch,
  supportToken: string,
}

const impersonateUser = (params: ImpersonateUserParams) => {
  const { dispatch, supportToken } = params
  const { hydrateCurrentUser: hydrateFn } = currentUserActions
  const { impersonateUser: impersonateFn } = userActions

  return dispatch(impersonateFn(supportToken, defaultRequestOptions))
    .then((response) => {
      const { success, data } = response

      if (success){
        dispatch(hydrateFn(data.entity))
      }

      window.location.hash = '/'

      return response
    })
}

export type UnlinkImpersonationParams = {
  dispatch: AppDispatch,
  redirect: boolean,
  userId: number,
}

const unlinkImpersonation = (params: UnlinkImpersonationParams) => {
  const { dispatch, redirect, userId } = params
  const { hydrateCurrentUser: hydrateFn } = currentUserActions
  const { unlinkImpersonation: unlinkFn } = userActions

  return dispatch(unlinkFn({ options: defaultRequestOptions, userId }))
    .then((response) => {
      const { success, data } = response

      if (success){
        dispatch(hydrateFn(data.entity))
      }

      if (redirect){
        window.location.hash = '/'
      }

      return response
    })
}

const defaultState = {
  supportToken: '',
}

function useUserImpersonation() {
  const [state, setState] = useSetState(defaultState)
  const { supportToken } = state

  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      impersonateUser: (suppliedToken: string) => (
        impersonateUser({ supportToken: suppliedToken || supportToken, dispatch })
      ),
      setState,
      unlinkImpersonation: ({ userId, redirect }: { userId: number, redirect: boolean }) => (
        unlinkImpersonation({ dispatch, redirect, userId })
      ),
    },
    supportToken,
  }
}

export default useUserImpersonation
