import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/agreement/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/agreement/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/agreement/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/agreement/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/agreement/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/agreement/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/agreement/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/agreement/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/agreement/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/agreement/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/agreement/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/agreement/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loadedShortcodeDataIds: [],
  loading: false,
  sandboxId: null,
  updating: false,
}

// Actions
export function fetchRequest(payload = {}){
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
    loadedShortcodeDataIds: payload.loadedShortcodeDataIds,
  }
}

export function fetchSuccess(payload = {}){
  return {
    type: FETCH_SUCCESS,
    sandboxId: payload.sandboxId,
  }
}

export function fetchFailure(errors = []){
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest(){
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(){
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []){
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest(){
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess(){
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []){
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest(){
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess(){
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []){
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function hydrateAgreement(agreementJson){
  return (dispatch) => {
    if (agreementJson.id){
      const normalizedJson = normalize(agreementJson, Schemas.AGREEMENT)
      dispatch(updateEntities(normalizedJson))
      return { success: true, data: agreementJson }
    }
    return { success: false, errors: ['Could not hydrate agreement without ID'] }
  }
}

export function loadAgreements(options = {}){
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().agreements.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/agreements.json', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.AGREEMENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadAgreement(agreementId, options){
  return (dispatch, getState) => {
    const loadedIds = [...getState().agreements.loadedIds]
    if (!loadedIds.includes(agreementId)){
      loadedIds.push(agreementId)
    }

    dispatch(fetchRequest({ loadedIds }))

    const promise = api(`/agreements/${agreementId}.json`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT)
        dispatch(updateEntities(normalizedJson))

        const sandboxId = agreementId === 'sandbox' ? data.entity?.id : null
        dispatch(fetchSuccess({ sandboxId }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createAgreement(agreement, options = {}){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      agreement,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agreements.json', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateAgreement(agreement, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      agreement,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agreements/${agreement.id}.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateRecipientSortOrder(agreement, sortedRecipientIds, options = {}){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      agreement,
      sorted_recipient_ids: sortedRecipientIds,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agreements/${agreement.id}/update_recipient_sort_order.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entities, Schemas.DOCUMENT_RECIPIENT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteAgreement(agreement){
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/agreements/${agreement.id}.json`, {}, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function loadAgreementShortcodeData(agreement, options){
  return (dispatch, getState) => {
    const loadedShortcodeDataIds = [...getState().agreements.loadedShortcodeDataIds]
    if (!loadedShortcodeDataIds.includes(agreement.id)){
      loadedShortcodeDataIds.push(agreement.id)
    }

    dispatch(fetchRequest({ loadedShortcodeDataIds }))

    const promise = api(`/agreements/${agreement.id}/shortcode_data.json`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT_SHORTCODE_DATA)
        dispatch(updateEntities(normalizedJson))

        const sandboxId = agreement.id === 'sandbox' ? data.entity?.id : null
        dispatch(fetchSuccess({ sandboxId }))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function createExternalAgreement(agreement, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/agreements/${agreement.id}/create_external_agreement.json`, options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function sendExternalAgreement(agreement, options){
  const config = {
    method: 'PUT',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agreements/${agreement.id}/send_external_agreement.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function generateExternalAgreementSigningUrl(agreement, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/agreements/${agreement.id}/generate_external_agreement_signing_url.json`, options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function voidExternalAgreement(agreement, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      agreement,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agreements/${agreement.id}/void_external_agreement.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteExternalAgreement(agreement, options){
  const config = {
    method: 'PUT',
    body: JSON.stringify({
      agreement,
    }),
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/agreements/${agreement.id}/destroy_external_agreement.json`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT)

        const updateEntityOptions = {
          mergeOptions: {
            customMerge: (key) => {
              if (key === 'data'){
                return (_, source) => source
              }

              return undefined
            },
          },
        }

        dispatch(updateEntities(normalizedJson, updateEntityOptions))
        dispatch(updateSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function loadExternalAgreementEvents(agreement, options){
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/agreements/${agreement.id}/external_agreement_events.json`, options)
      .then(({ data }) => {
        dispatch(fetchSuccess())

        return { success: true, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

export function createExternalForm(agreement, options = {}){
  const config = {
    method: 'POST',
    body: JSON.stringify({
      agreement,
    }),
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api('/agreements/create_external_form.json', options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data.entity, Schemas.AGREEMENT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// TODO: Determine the necessity of this action, as it is not being used anywhere.
export function generateExternalFormUrl(agreement, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/agreements/${agreement.id}/generate_external_form_url.json`, options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

// TODO: Determine the necessity of this action, as it is not being used anywhere.
export function generateNativeAppUrl(agreement, options){
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/agreements/${agreement.id}/native_app_url.json`, options, config)
      .then(({ data }) => {
        dispatch(createSuccess())

        return { success: true, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}){
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loadedShortcodeDataIds: action.loadedShortcodeDataIds || state.loadedShortcodeDataIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        sandboxId: action.sandboxId || state.sandboxId,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
