import { useSelector } from 'react-redux'

import { digObject } from '@campaignhub/javascript-utils'

import useReduxAction from '@hooks/useReduxAction'

import {
  isAdmin, isBrandUser, isMasterAdmin, isOrganizationUser,
} from '@functions/user'

import { UserModel } from '@models/types'

export const defaultRequestOptions = {
  include_user_brands_where_admin_ids: true,
  include_user_default_brand: true,
  include_user_default_organization_id: true,
  include_user_default_organization: true,
  include_user_impersonated_user: true,
  include_user_organizations_where_admin_ids: true,
  include_user_organizations_where_owner_ids: true,
  include_user_role: true,
}

type UseCurrentUserOptions = {
  performHttpRequests?: boolean,
}

function useCurrentUser(options: UseCurrentUserOptions = {}) {
  const { performHttpRequests } = options || {}

  useReduxAction(
    'currentUser',
    'loadCurrentUser',
    defaultRequestOptions,
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors.length
      },
    },
  )

  const entityReducer = useSelector(reduxState => reduxState.currentUser)
  const { loaded, loading, result: currentUserResult } = entityReducer

  const impersonatingUser = currentUserResult
  const impersonatedUser: Partial<UserModel> = currentUserResult.impersonated_user || {}
  const isImpersonatingUser = !!impersonatedUser.id

  const currentUser: UserModel = isImpersonatingUser ? impersonatedUser : currentUserResult

  const userRoleTitle: string = digObject(currentUser, 'role.title', '')
  const userRoleKey: string = digObject(currentUser, 'role.key', '')

  const organizationsWhereAdminIds: number[] = digObject(currentUser, 'organizations_where_admin_ids', [])

  return {
    currentUser,
    hasOrganizationsWhereAdmin: !!organizationsWhereAdminIds.length,
    impersonatedUser,
    impersonatingUser,
    isAdmin: isAdmin(userRoleKey),
    isBrandUser: isBrandUser(userRoleKey),
    isImpersonatingUser,
    isMasterAdmin: isMasterAdmin(userRoleKey),
    isOrganizationUser: isOrganizationUser(userRoleKey),
    loaded,
    loading,
    organizationsWhereAdminIds,
    userRoleKey,
    userRoleTitle,
  }
}

export default useCurrentUser
