import { digObject } from '@campaignhub/javascript-utils'

import type { AgreementModel } from '../models/agreement'

type BuildExternalAgreementUrlParams = {
  agreement: AgreementModel,
}

export const availableFeatures = [
  { key: 'project_comparables', label: 'Comparables' },
  { key: 'quotes', label: 'Quotes' },
]

export const buildExternalAgreementUrl = (params: BuildExternalAgreementUrlParams) => {
  const { agreement } = params

  const createFormPlatformKey = digObject(agreement, 'data.create_form_platform')

  const createFormExternalId = digObject(agreement, `data.external_ids.${createFormPlatformKey}`)

  if (createFormPlatformKey === 'real_time_agent'){
    const url = new URL(
      `/redirect/?page=edit-authority&id=${createFormExternalId}`,
      'https://app.realtimeagent.com.au',
    )
    return url?.toString()
  }

  return undefined
}

export default {
  availableFeatures,
  buildExternalAgreementUrl,
}
